import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Highlight from './components/Highlight';
import Services from './components/Services';
import ServicesB from './components/ServicesB';
import Tecnologies from './components/Technologies';
import About from './components/About'
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
    return ( <
        div className = "App" >
            <Header />
            <Highlight></Highlight>
            <Services />
            <About />
            <ServicesB />
            <Tecnologies />
            <Contact />
            <Footer />
            <div className='whatsapp'>
                <a href="https://wa.me/50230998948" target='_blank'>
                    <FontAwesomeIcon className='icon' icon={faWhatsapp}></FontAwesomeIcon>
                </a>
            </div>
        </div>
    );
}

export default App;