import React from 'react';

const Technologies = () =>{
    return (
        <div id="tech">
            <h2>Expertos en</h2>
            <div className="inners">
                <ul>
                    <li><img src="/images/logos/aws.png" alt='' /></li>
                    <li><img src="/images/logos/docker.png"  alt=''/></li>
                    <li><img src="/images/logos/git.png"  alt=''/></li>
                    <li><img src="/images/logos/reactjs.png"  alt=''/></li>
                    <li><img src="/images/logos/mysql.png"  alt=''/></li>
                    <li><img src="/images/logos/mongodb.png"  alt=''/></li>
                    <li><img src="/images/logos/css3.png"  alt=''/></li>
                    <li><img src="/images/logos/html5.png"  alt=''/></li>
                    <li><img src="/images/logos/js.png"  alt=''/></li>
                </ul>
            </div>
        </div>
    )
}

export default Technologies;