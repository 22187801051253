import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const About = () => {
    return(
        <div id="about">
            <div className="inner">
                <h2>About us</h2>
                <span>{process.env.REACT_APP_COMPANY} está integrado por un grupo de profesionales, dedicados a ofrecerte soluciones personalizadas para tu empresa. Tenemos un gran espíritu de compromiso y la atención al cliente es nuestra pasión.</span>
                <div className="canvas">
                </div>
            </div>
            <a className='btn btn-whatsapp'><FontAwesomeIcon className='icon' icon={faWhatsapp}></FontAwesomeIcon> Háblanos!</a>
        </div>
    )
}

export default About;