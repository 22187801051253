import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/themes/splide-skyblue.min.css';

const ServicesB = () => {
    return (
        <div id="servicesB">
            <Splide aria-label="My Favorite Images">
                <SplideSlide>
                    <div className='ul'>
                        <div className="left">
                            <h2>Sitios Web</h2>
                            <span>Sitios web Corporativos, Catálogos, Carritos de compras, Blogs, Sitios web de noticias, Administrador de contenidos y más.</span>
                        </div>
                        <div className="right demo">
                            <img src="./images/website.png" alt=":)"></img>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className='ul'>
                        <div className="left">
                            <h2>Aplicaciones Móviles</h2>
                            <span>Aplicaciones de compras en línea, noticias, entretenimiento...</span>
                        </div>
                        <div className="right demo">
                            <img src="./images/appmobile.png" alt=":)"></img>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className='ul'>
                        <div className="left">
                            <h2>Diseño Gráfico</h2>
                            <span>Branding, Imagen Corporativa, Diseño Web, Diseño Gráfico...</span>
                        </div>
                        <div className="right demo">
                            <img src="./images/graphicdesign.png" alt=":)"></img>
                        </div>                        
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className='ul'>
                        <div className="left">
                            <h2>Migración a la Nube</h2>
                            <span>Asesoría de Migración de servicios en la Nube con AWS, Google Cloud, Digital Ocean. Dominios, Almacenamiento, Streaming, Backups, etc.</span>
                        </div>
                        <div className="right demo">
                            <img src="./images/cloudcomputing.png" alt=":)"></img>
                        </div>
                    </div>
                </SplideSlide>
            </Splide>
        </div>
    )
}

export default ServicesB;