import React from 'react';

const Highlight = () => {
    return (
        <div className="highlight" id="home">
            <div className="welcome">
                <h1>Bienvenidos a <span>{process.env.REACT_APP_COMPANY}</span></h1>
                <p>{process.env.REACT_APP_SLOGAN}</p>
                <a href="#about">Acerca de nosotros</a>
            </div>
            <div className="bg"></div>
        </div>
    )
}

export default Highlight;